// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('Vacations/getAllData', async (Vacations) => {
  const config = {     
    headers: { 'Content-Type': 'application/json' }
}
  const getAllApi = "Requests/postall"
  const response = await axios.post(themeConfig.data.API + getAllApi, JSON.stringify(Vacations), config)
 // console.log("uuu", JSON.parse(response.data.data))
  return JSON.parse(response.data.data)
})

export const VacationsSlice = createSlice({
  name: 'Vacations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCountry: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.allData.data = action.payload.DataTable
        state.data = action.payload.DataTable
      })
  }
})

export default VacationsSlice.reducer
