// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('Mathedds/getAllData', async (Mathedd) => {
  const config = {     
    headers: { 'Content-Type': 'application/json' }
}
  const getAllApi = "Attendance/Post"
  const response = await axios.post(themeConfig.data.API + getAllApi, JSON.stringify(Mathedd), config)
  console.log("uuu", JSON.parse(response.data.data))
  return JSON.parse(response.data.data)
})
export const addMathedd = createAsyncThunk('Mathedd/Add', async (Mathedd, { dispatch, getState }) => {
  await axios.post('http://174.142.60.74:8056/Requests/post', Mathedd)
  console.log(getState())
  await dispatch(getData(getState().Mathedd.params))
  await dispatch(getAllData())
  return Mathedd
})
export const MatheddSlice = createSlice({
  name: 'RelRoll',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCountry: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.allData.data = action.payload.AT_MONTHLYATTEND
        state.data = action.payload.AT_MONTHLYATTEND
      })
  }
})

export default MatheddSlice.reducer
