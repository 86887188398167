// ** Auth Endpoints
import themeConfig from '@configs/themeConfig'

export default {
  loginEndpoint: themeConfig.data.API+ "Users/Login",
  registerEndpoint: '/jwt/register',
  //refreshEndpoint: 'https://localhost:44394/api/Authenticate/refresh',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
