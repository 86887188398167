// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('RQCUSTODYE/getAllData', async (RQCUSTODYE) => {
  const config = {     
    headers: { 'Content-Type': 'application/json' }
}
  const getAllApi = "Requests/postall"
  const response = await axios.post(themeConfig.data.API + getAllApi, JSON.stringify(RQCUSTODYE), config)
  console.log("uuu", JSON.parse(response.data.data))
  return JSON.parse(response.data.data)
})
export const addRQCUSTODYES = createAsyncThunk('RQCUSTODYES/Add', async (RQCUSTODYES, { dispatch, getState }) => {
  await axios.post('http://174.142.60.74:8056/Requests/post', RQCUSTODYES)
  console.log(getState())
  await dispatch(getData(getState().RQCUSTODYES.params))
  await dispatch(getAllData())
  return RQCUSTODYES
})
export const RQCUSTODYESSlice = createSlice({
  name: 'RQCUSTODYES',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCountry: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.allData.data = action.payload.DataTable
        state.data = action.payload.DataTable
      })
  }
})

export default RQCUSTODYESSlice.reducer
