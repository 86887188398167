// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('RQAssess/getAllData', async (RQAsses) => {
  const config = {     
    headers: { 'Content-Type': 'application/json' }
}
  const getAllApi = "Requests/postall"
  const response = await axios.post(themeConfig.data.API + getAllApi, JSON.stringify(RQAsses), config)
  console.log("uuu", JSON.parse(response.data.data))
  return JSON.parse(response.data.data)
})
export const addRQAsses = createAsyncThunk('RQAsse/Add', async (RQAsses, { dispatch, getState }) => {
  await axios.post('http://174.142.60.74:8056/Requests/post', RQAsses)
  console.log(getState())
  await dispatch(getData(getState().RQAsses.params))
  await dispatch(getAllData())
  return RQAsses
})

export const RQAssessSlice = createSlice({
  name: 'RQAssess',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCountry: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.allData.data = action.payload.DataTable
        state.data = action.payload.DataTable
      })
  }
})

export default RQAssessSlice.reducer
