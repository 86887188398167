// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import employees from '@src/views/apps/employees/store'
import Vacations from '@src/views/apps/Vacations/store'
import RQPermissions from '@src/views/apps/RQPermissions/store'
import LoanRequest from '@src/views/apps/LoanRequest/store'
import RQCUSTODYES from '@src/views/apps/RQCUSTODYES/store'

import RelRoll from '@src/views/apps/RelRoll/store'
import Mathedd from '@src/views/apps/Mathedd/store'
//import ExpiredDocument from '@src/views/apps/ExpiredDocument/store'
//import Reminders from '@src/views/apps/ExpiredDocument/store'


import RQAssess from '@src/views/apps/RQAssess/store'
const rootReducer = {
  auth,
  
  employees,
  Vacations,
  RQPermissions,
  RQAssess,
  LoanRequest,
  RQCUSTODYES,
  Mathedd,
 // ExpiredDocument,
  RelRoll,
  //Reminders,
  navbar,
  layout
 
}

export default rootReducer
