// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('RelRolls/getAllData', async (RelRoll) => {
  const config = {     
    headers: { 'Content-Type': 'application/json' }
}
  const getAllApi = "PayRoll/post"
  const response = await axios.post(themeConfig.data.API + getAllApi, JSON.stringify(RelRoll), config)

  return JSON.parse(response.data.data)
})
export const addRelRoll = createAsyncThunk('RelRoll/Add', async (RelRoll, { dispatch, getState }) => {
  await axios.post(themeConfig.data.API +'Requests/post', RelRoll)

  await dispatch(getData(getState().RelRoll.params))
  await dispatch(getAllData())
  return RelRoll
})
export const RelRollSlice = createSlice({
  name: 'RelRoll',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCountry: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
        state.allData.data = action.payload.DataTable
        state.data = action.payload.DataTable
      })
  }
})

export default RelRollSlice.reducer
